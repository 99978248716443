exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-certificate-js": () => import("./../../../src/pages/certificate.js" /* webpackChunkName: "component---src-pages-certificate-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-facts-and-feats-js": () => import("./../../../src/pages/facts-and-feats.js" /* webpackChunkName: "component---src-pages-facts-and-feats-js" */),
  "component---src-pages-hole-in-one-records-js": () => import("./../../../src/pages/hole-in-one-records.js" /* webpackChunkName: "component---src-pages-hole-in-one-records-js" */),
  "component---src-pages-hole-in-one-registry-lookup-js": () => import("./../../../src/pages/hole-in-one-registry-lookup.js" /* webpackChunkName: "component---src-pages-hole-in-one-registry-lookup-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-hole-in-one-js": () => import("./../../../src/pages/register-hole-in-one.js" /* webpackChunkName: "component---src-pages-register-hole-in-one-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

